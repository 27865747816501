import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { UpdateSubcategoryComponent } from './update-subcategory/update-subcategory.component';
import { AdminModuleServiceService } from '../admin-module-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

export interface assignSubDataInterface {
  ItemCategoriesId: number,
  ItemCategory: string;
};

@Component({
  selector: 'app-admin-subcategory',
  templateUrl: './admin-subcategory.component.html',
  styleUrls: ['./admin-subcategory.component.sass']
})
export class AdminSubcategoryComponent implements OnInit {
  UserTypeId: any;
  SystemId: any;

  EmployeeId: any;
  CategoryFormGroup: FormGroup;
  allCategory:Object;
  AllProducts: Object;
  SCategoryId: any;
  UserId: any;
  Category: any;
  constructor(
    private service: AdminModuleServiceService,
    private route: Router, private router: ActivatedRoute, public dialog: MatDialog) { }


  allassignSub!: assignSubDataInterface[];
  assignSubDisplayColumn = ['Sr.No','Category Name','Description','Action'];
  assignSubDataSource = new MatTableDataSource(this.allassignSub)

  searchClient(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.assignSubDataSource.filter = filterValue.trim().toLowerCase();
  }

  async getCategory() {
    const teacher = await this.service.getAllSCategory().toPromise();
    console.log(teacher,"teacher");
    this.assignSubDataSource.data = teacher as assignSubDataInterface[]
  }

  fnOpenUpdateProduct(SCategoryId:any) {
   let dialogref = this.dialog.open(UpdateSubcategoryComponent,{
      data:{
        SCategoryId,
        Text:true
      },
      disableClose:true});

    // this.dialog._getAfterAllClosed().subscribe(result => {
    //       this.getProducts();
    // });

  }

  ngOnInit(): void {
    const data = JSON.parse(localStorage.getItem('access_token'))
    console.log(data);

    this.UserId = data.user.UserId;
    this.UserTypeId = data.user.UserType;
    this.SystemId = data.user.SystemId;
   
    if (data == null) {
      this.route.navigate(['']);
    }

    // this.getHomework();
    this.fngetCategoryID();
   this.getCategory();
   this.getCatagoryName();
    this.CategoryFormGroup = new  FormGroup({
      SCategoryId : new FormControl(''),
      SCategoryName: new FormControl(''),
      CategoryId: new FormControl(''),
      // CategoryId1 : new FormControl(''),
      SCategoryDescription :new FormControl(''),  
      // SystemId: new FormControl(''),
      // CreatedBy: new FormControl(''),
    })

  }

  tempSubjectGrp(templateRef: any): void {
    let dialogRef = this.dialog.open(templateRef, { disableClose: true });
    }
 
  fngetCategoryID() {
    this.service.SCategoryId().subscribe((response) => {
      this.AllProducts = response
      if (this.AllProducts != 0) {
        this.SCategoryId = this.AllProducts[0].SCategoryId + 1
        console.log(this.SCategoryId,'Products Id')
      }
      else {
        this.SCategoryId = 1;
      }
    })
  }

  fnaddCategory(){
    this.service.createSCategory(this.CategoryFormGroup.value).subscribe((response) => {
      // this.toastrService.success(':: Add Product Succesfully!');
      this.getCategory();
      this.clrfun();
    });

    
   
  }
     clrfun(){
      this.CategoryFormGroup.controls.SCategoryName.reset();
      this.CategoryFormGroup.controls.SCategoryDescription.reset();
      this.CategoryFormGroup.controls.CategoryId.reset();

     } 
    refresh() {
      
    }

    deleteCategory(id:any){
    
        
            this.service.deleteSCategory(id).subscribe((data)=>{
              this.service.warn('Deleted Succesfully')
              this.getCategory();
            // this.t
               })
      } 

      getCatagoryName(){
        this.service.getCategory(this.SystemId).subscribe((data) => {
          this.allCategory = data;
          this.Category = data;
          console.log("allStandard", this.allCategory);
        })
      }

}
