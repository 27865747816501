import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { MediaObserver, MediaChange } from '@angular/flex-layout';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
@Component({
  selector: 'app-admin-layout',
  templateUrl: './admin-layout.component.html',
  styleUrls: ['./admin-layout.component.sass']
})
export class AdminLayoutComponent implements OnInit {
  sideNavOpened=true;
  sideNavMode: 'side' | 'over' = "side";
  sideBarToggler()
  {
    this.sideNavOpened = !this.sideNavOpened; 
  }

  private mediaWatcher: Subscription = new Subscription;
  toolBarHeight = 64;

  constructor(
    private cdRef: ChangeDetectorRef,
    private mediaObservor:MediaObserver,private router:Router
  ) {


    this.mediaWatcher = mediaObservor.media$.subscribe((change: MediaChange) => {
      if (change.mqAlias === 'sm' || change.mqAlias === 'xs') {
        if (this.sideNavOpened) {
          this.sideNavOpened = false;
        }
        this.sideNavMode = 'over';
      } else {
        this.sideNavOpened = true;
        this.sideNavMode = 'side';
      }
      if (change.mqAlias === 'xs') {
        this.toolBarHeight = 56;
      } else {
        this.toolBarHeight = 64;
      }
    });
  }

  ngOnInit(): void {

    const data = JSON.parse(localStorage.getItem('access_token'))
    if(data==null){
      this.router.navigate(['']);
    }
   
    this.mediaWatcher = this.mediaObservor.media$.subscribe(
      (change:MediaChange)=>{
        // console.log(change.mqAlias);
      }
    );
  }
  ngAfterViewInit(){}
  ngOnDestroy(){
    if(this.mediaWatcher)
    {
      this.mediaWatcher.unsubscribe();
    }
  }

}
