import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ElementRef } from '@angular/core';

import { AdminModuleServiceService } from '../admin-module-service.service';
import {  FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateCatagoriesComponent } from './update-catagories/update-catagories.component';
// import { UpdateProductComponent } from './update-product/update-product.component';

export interface assignSubDataInterface {
  ItemCategoriesId: number,
  ItemCategory: string;
};
@Component({
  selector: 'app-admin-categorie',
  templateUrl: './admin-categorie.component.html',
  styleUrls: ['./admin-categorie.component.sass']
})
export class AdminCategorieComponent implements OnInit {
  @ViewChild('HomeworkDate') HomeworkDate: ElementRef;
  @ViewChild('SubmissionDate') SubmissionDate: ElementRef;

  UserTypeId: any;
  SystemId: any;

  EmployeeId: any;
  CategoryFormGroup: FormGroup;
 
  AllProducts: Object;
  CategoryId: any;
  UserId: any;
  
  constructor(
    private service: AdminModuleServiceService,
    private route: Router, private router: ActivatedRoute, public dialog: MatDialog) { }


  allassignSub!: assignSubDataInterface[];
  assignSubDisplayColumn = ['Sr.No','Category Name','Description','Action'];
  assignSubDataSource = new MatTableDataSource(this.allassignSub)

  searchClient(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.assignSubDataSource.filter = filterValue.trim().toLowerCase();
  }

  async getCategory() {
    const teacher = await this.service.getAllCategory(this.SystemId).toPromise();
    console.log(teacher,"teacher");
    this.assignSubDataSource.data = teacher as assignSubDataInterface[]
  }

  fnOpenUpdateProduct(CategoryId:any) {
   let dialogref = this.dialog.open(UpdateCatagoriesComponent,{
      data:{
        CategoryId,
        Text:true
      },
      disableClose:true});

    // this.dialog._getAfterAllClosed().subscribe(result => {
    //       this.getProducts();
    // });

  }

  ngOnInit(): void {
    const data = JSON.parse(localStorage.getItem('access_token'))
    console.log(data);

    this.UserId = data.user.UserId;
    this.UserTypeId = data.user.UserType;
    this.SystemId = data.user.SystemId;
   
    if (data == null) {
      this.route.navigate(['']);
    }

    // this.getHomework();
    this.fngetCategoryID();
   this.getCategory();
    this.CategoryFormGroup = new  FormGroup({
      CategoryId : new FormControl(''),
      CategoryName: new FormControl(''),
      CategoryDescription :new FormControl(''),  
      SystemId: new FormControl(''),
      CreatedBy: new FormControl(''),
    })

  }

  tempSubjectGrp(templateRef: any): void {
    let dialogRef = this.dialog.open(templateRef, { disableClose: true });
    }
 
  fngetCategoryID() {
    this.service.getcatgoryId().subscribe((response) => {
      this.AllProducts = response
      if (this.AllProducts != 0) {
        this.CategoryId = this.AllProducts[0].CategoryId + 1
        console.log(this.CategoryId,'Products Id')
      }
      else {
        this.CategoryId = 1;
      }
    })
  }

  fnaddCategory(){
    this.service.createCategory(this.CategoryFormGroup.value).subscribe((response) => {
      // this.toastrService.success(':: Add Product Succesfully!');
      this.getCategory();
      this.clrfun();
    });

    
   
  }
     clrfun(){
      this.CategoryFormGroup.controls.CategoryName.reset();
      this.CategoryFormGroup.controls.CategoryDescription.reset();

     } 
    refresh() {
      
    }

    deleteCategory(id:any){
    
        
            this.service.deleteCategory(id).subscribe((data)=>{
              this.service.warn('Deleted Succesfully')
              this.getCategory();
            // this.t
           
               })
            
         
      } 

     
}
