import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminModuleRoutingModule } from './admin-module-routing.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { FlexLayoutModule } from '@angular/flex-layout';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminProductComponent } from './admin-product/admin-product.component';
import { AdminCategorieComponent } from './admin-categorie/admin-categorie.component';
import { AdminMainComponent } from './admin-main/admin-main.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatInputModule } from '@angular/material/input';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatIconModule } from '@angular/material/icon';
import { MatRadioModule } from '@angular/material/radio';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import {MatSnackBarModule} from '@angular/material/snack-bar';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCardModule } from '@angular/material/card';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatTabsModule } from '@angular/material/tabs';
import { MatListModule } from '@angular/material/list';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatTableModule } from '@angular/material/table';
import { MatMenuModule } from '@angular/material/menu';
import { MatSortModule } from '@angular/material/sort';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatStepperModule } from '@angular/material/stepper';
import {MatGridListModule} from '@angular/material/grid-list';
import { UpdateProductComponent } from './admin-product/update-product/update-product.component';
// import { AdminBrowsproductComponent } from './admin-browsproduct/admin-browsproduct.component';
import { UpdateBrowseComponent } from './admin-browsproduct/update-browse/update-browse.component';
import { BannersComponent } from '../components/shared/banners/banners.component';
import { AdminEditnewsComponent } from './admin-editnews/admin-editnews.component';
import { UpdateCatagoriesComponent } from './admin-categorie/update-catagories/update-catagories.component';
import { AdminSubcategoryComponent } from './admin-subcategory/admin-subcategory.component';
import { AdminBrandComponent } from './admin-brand/admin-brand.component';
import { UpdateSubcategoryComponent } from './admin-subcategory/update-subcategory/update-subcategory.component';
import { UpdateBrandComponent } from './admin-brand/update-brand/update-brand.component';





@NgModule({
  declarations: [AdminLoginComponent, AdminProductComponent, AdminCategorieComponent, AdminMainComponent, UpdateProductComponent, UpdateBrowseComponent, AdminEditnewsComponent, UpdateCatagoriesComponent, AdminSubcategoryComponent, AdminBrandComponent, UpdateSubcategoryComponent, UpdateBrandComponent],
  imports: [
    CommonModule,
    AdminModuleRoutingModule,
    MatSidenavModule,
    MatDividerModule,
    MatCheckboxModule,
    MatInputModule,
    MatFormFieldModule,
    MatButtonModule,
    MatSelectModule,
    MatInputModule,
    MatIconModule,
    FormsModule,
    ReactiveFormsModule.withConfig({warnOnNgModelWithFormControl: 'never'}),
    MatRadioModule,
    MatTooltipModule,
    MatDialogModule,
    MatSnackBarModule,
    MatToolbarModule,
    MatCardModule,
    FlexLayoutModule,
    MatExpansionModule,
    MatSlideToggleModule,
    MatTabsModule,
    MatListModule,
    MatTableModule,
    MatMenuModule,
    MatSortModule,
    MatDatepickerModule,
    // MatNativeDateModule,
    MatPaginatorModule,
    HttpClientModule,
    MatAutocompleteModule,
    MatStepperModule,
    MatGridListModule,
  ],
  exports: [
    UpdateBrowseComponent
  ]

})
export class AdminModuleModule { }
