import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfirmdialogComponent } from '../components/shared/confirmdialog/confirmdialog.component';
import { MatSnackBar, MatSnackBarConfig } from '@angular/material/snack-bar';
import { Observable, Subject } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';


@Injectable({
  providedIn: 'root'
})
export class AdminModuleServiceService {

  
  httpHeaders = new HttpHeaders({ 'Content-Type': 'application/json',
  'Access-Control-Allow-Origin': '*',
  'Access-Control-Allow-Headers': 'Content-Type',
  'Access-Control-Allow-Methods': 'GET,POST,OPTIONS,DELETE,PUT',
   })

   constructor(private http: HttpClient, private dialog: MatDialog, private snackbar: MatSnackBar) { }

   openConfirmDialog(msg: any) {
    return this.dialog.open(ConfirmdialogComponent, {
      width: '390px',
      panelClass: 'confirm-dialog-container',
      disableClose: true,
      data: {
      }
    });
  }



  private sharedData: Subject<any> = new Subject<any>();
  sharedData$: Observable<any> = this.sharedData.asObservable();



  config: MatSnackBarConfig = {
    duration: 3000,
    horizontalPosition: 'right',
    verticalPosition: 'bottom'
  }
  success(msg: any) {
    this.config['panelClass'] = ['notification', 'success']
    this.snackbar.open(msg, '', this.config);
  }
  warn(msg: any) {
    this.config['panelClass'] = ['notification', 'warn']
    this.snackbar.open(msg, '', this.config);
  }
   getuser(id: any, Sid: any){
    return this.http.get(`${environment.Auth_URL}getuser/${id}/${Sid}`,
      { headers: this.httpHeaders })
   }  

   userLogin(user: any) {
    return this.http.post(`${environment.Auth_URL}login`, user)
  }

    setData(updatedData) {
    this.sharedData.next(updatedData);
  }
  // Product

  //get Product Id
  getProductLastId() {
    return this.http.get(`${environment.Admin_URL}Product/productid`, { headers: this.httpHeaders })
  }
  
  createProduct(form:any){
    return this.http.post(`${environment.Admin_URL}Product/add`,form,{headers: this.httpHeaders})
  }

  getAllProducts(id:number) {
    return this.http.get(`${environment.Admin_URL}Product/Allproducts/${id}`, { headers: this.httpHeaders })
    }

    getProductByCategoryId(id:any) {
    return this.http.get(`${environment.Admin_URL}Product/categoryid/${id}`, { headers: this.httpHeaders }).pipe(
      map((response:[]) => response.map(item => item))
    );
    }


  deleteProduct(id: number) {
    return this.http.delete(`${environment.Admin_URL}Product/delete/${id}`,{ headers: this.httpHeaders })
  }

  getSingleProduct(id:any) {
    return this.http.get(`${environment.Admin_URL}Product/singleProduct/${id}`, { headers: this.httpHeaders })
  }

  updateProduct(id,data) {
    return this.http.put(`${environment.Admin_URL}Product/updateProduct/${id}`,data, { headers: this.httpHeaders })
  }

 
  
   //get category Id
   getcatgoryId() {
    return this.http.get(`${environment.Admin_URL}category/getcatgoryId`, { headers: this.httpHeaders })
  }
  
  createCategory(form:any){
    return this.http.post(`${environment.Admin_URL}category/add`,form,{headers: this.httpHeaders})
  }

  getAllCategory(id:number) {
    return this.http.get(`${environment.Admin_URL}category/AllCategory/${id}`, { headers: this.httpHeaders })
    }

    deleteCategory(id: number) {
    return this.http.delete(`${environment.Admin_URL}category/delete/${id}`,{ headers: this.httpHeaders })
  }

  getSingleCategory(id:any,sid:any) {
    return this.http.get(`${environment.Admin_URL}category/singleCategory/${id}/${sid}`, { headers: this.httpHeaders })
  }

  updateCategory(id,data) {
    return this.http.put(`${environment.Admin_URL}category/updateCategory/${id}`,data, { headers: this.httpHeaders })
  }

  sendEmail(url, data) {
    return this.http.post(url, data);
  }

  getCategory(id:any) {
    return this.http.get(`${environment.Admin_URL}category/getCategory/${id}`,{ headers: this.httpHeaders });
  }

  getProducts(id:any) {
    return this.http.get(`${environment.Admin_URL}Product/getProduct/${id}`,{ headers: this.httpHeaders });
  }

  getProducts1(id:any) {
    return this.http.get(`${environment.Admin_URL}Product/categoryid/${id}`,{ headers: this.httpHeaders });
  }

  createProfile(form:any){
    return this.http.post(`${environment.Admin_URL}prodProfile/productimg`,form)  
  }

  getLastprductphotoId() {
    return this.http.get(`${environment.Admin_URL}prodProfile/LastLogoId`, { headers: this.httpHeaders })
  }
  updateproductProfile(id,data) {
    return this.http.put(`${environment.Admin_URL}prodProfile/produpdate/${id}`,data, { headers: this.httpHeaders })
  }

  //subcatagory///////////////////////////////////////////////////////////////////////////////////////////////
  SCategoryId() {
    return this.http.get(`${environment.Admin_URL}subcategory/getSCategoryId`, { headers: this.httpHeaders })
  }
  
  createSCategory(form:any){
    return this.http.post(`${environment.Admin_URL}subcategory/addSCategory`,form,{headers: this.httpHeaders})
  }

  getAllSCategory() {
    return this.http.get(`${environment.Admin_URL}subcategory/AllSCategory`, { headers: this.httpHeaders })
    }

    deleteSCategory(id: number) {
    return this.http.delete(`${environment.Admin_URL}subcategory/deleteSCategory/${id}`,{ headers: this.httpHeaders })
  }

  getSingleSCategory(id:any) {
    return this.http.get(`${environment.Admin_URL}subcategory/singleSCategory/${id}`, { headers: this.httpHeaders })
  }

  updateSCategory(id,data) {
    return this.http.put(`${environment.Admin_URL}subcategory/updateSCategory/${id}`,data, { headers: this.httpHeaders })
  }

  getscatagoryByCategoryId(id:any) {
    return this.http.get(`${environment.Admin_URL}subcategory/subcategoryid/${id}`, { headers: this.httpHeaders })
    }

  //Brand///////////////////////////////////////////////////////////////////////////////////////////////
  getBrandId() {
    return this.http.get(`${environment.Admin_URL}Brand/brands/getBrandatgoryId`, { headers: this.httpHeaders })
  }
  


  getBrandIdC(id:any) {
    return this.http.get(`${environment.Admin_URL}Brand/getBrand/${id}`, { headers: this.httpHeaders })
  }
  createBrand(form:any){
    return this.http.post(`${environment.Admin_URL}Brand/addBrand`,form,{headers: this.httpHeaders})
  }

  getAllBrand() {
    return this.http.get(`${environment.Admin_URL}Brand/AllBrand`, { headers: this.httpHeaders })
    }
  getSCategory(id:any) {
    return this.http.get(`${environment.Admin_URL}subcategory/getSCategory/byC/${id}`,{ headers: this.httpHeaders });
  }

  getSingleBrand(id:any) {
    return this.http.get(`${environment.Admin_URL}Brand/singleBrand/${id}`, { headers: this.httpHeaders })
  }

  updateBrand(id,data) {
    return this.http.put(`${environment.Admin_URL}Brand/updateBrand/${id}`,data, { headers: this.httpHeaders })
  }

  deleteBrand(id: number) {
    return this.http.delete(`${environment.Admin_URL}Brand/deleteBrand/${id}`,{ headers: this.httpHeaders })
  }

}
