import {RouterModule, Routes} from '@angular/router';




export const appRoutes: Routes = [
 
  {
    path: '',
    loadChildren: () => import('./../components/shop/shop.module').then(m => m.ShopModule)
  },
  {
    path: 'home',
    loadChildren: () => import('./../components/shop/shop.module').then(m => m.ShopModule)
  },
  {
    path: 'pages',
    loadChildren: () => import('./../components/pages/pages.module').then(m => m.PagesModule)
  },
  {
    path: 'blog',
    loadChildren: () => import('./../components/blog/blog.module').then(m => m.BlogModule)
  },
  {
    path: 'admin-product',
    loadChildren: () => import('../admin-module/admin-module.module').then(m=>m.AdminModuleModule)
  }
 
];

