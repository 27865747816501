import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ElementRef } from '@angular/core';

import { AdminModuleServiceService } from '../admin-module-service.service';
import {  FormControl, FormGroup, Validators } from '@angular/forms';
import { UpdateProductComponent } from './update-product/update-product.component';
import { HttpClient } from '@angular/common/http';

export interface assignSubDataInterface {
  ItemCategoriesId: number,
  ItemCategory: string;
};


@Component({
  selector: 'app-admin-product',
  templateUrl: './admin-product.component.html',
  styleUrls: ['./admin-product.component.sass']
})
export class AdminProductComponent implements OnInit {
  @ViewChild('HomeworkDate') HomeworkDate: ElementRef;
  @ViewChild('SubmissionDate') SubmissionDate: ElementRef;
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;

  public image : string = 'https://www.w3schools.com/howto/img_avatar.png';

  UserTypeId: any;
  SystemId: any;

  EmployeeId: any;
  ProductFormGroup: FormGroup;
  productimgFormGroup: FormGroup;
  allStandard: Object;
  fileInputLabel: string;
  AllProducts: Object;
  ProductId: any;
  UserId: any;
  Category: any;
  allpicId: any;
  prductphotoId: any;
  ProfilePhotoId: any;
  allsubCategory: Object;
  BrandAll: Object;
  

  constructor(
    private service: AdminModuleServiceService,
    private route: Router, private router: ActivatedRoute, public dialog: MatDialog,private http: HttpClient) { }

    @Output() selectFile: EventEmitter<Object> = new EventEmitter();


  allassignSub!: assignSubDataInterface[];
  assignSubDisplayColumn = ['Sr.No','Product Name','Description','Category','Action'];
  assignSubDataSource = new MatTableDataSource(this.allassignSub)

  searchClient(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.assignSubDataSource.filter = filterValue.trim().toLowerCase();
  }

  async getProducts() {
    const teacher = await this.service.getAllProducts(this.SystemId).toPromise();
    // console.log(teacher,"teacher");
    this.assignSubDataSource.data = teacher as assignSubDataInterface[]
  }

  fnOpenUpdateProduct(ProductId:any) {
   let dialogref = this.dialog.open(UpdateProductComponent,{
      data:{
        ProductId,
        Text:true
      },
      disableClose:true});

    // this.dialog._getAfterAllClosed().subscribe(result => {
    //       this.getProducts();
    // });

  }

  ngOnInit(): void {
    const data = JSON.parse(localStorage.getItem('access_token'))
    // console.log(data);
    

    this.UserId  = data.user.UserId ;
    this.UserTypeId = data.user.UserType;
    this.SystemId = data.user.SystemId;
  //  console.log(this.SystemId);
    if (data == null) {
      this.route.navigate(['']);
    }

    // this.getHomework();
    this.fngetProductID();
   this.getProducts();
   this.getStandardName();
   this.fngetPicId();
    this.ProductFormGroup = new  FormGroup({
      ProductId : new FormControl(''),
      ProductName: new FormControl(''),
      Description :new FormControl(''),  
      BrandId:new FormControl(''),  
      CategoryId: new FormControl(''),
      prductphotoId: new FormControl(''),
      SystemId: new FormControl(''),
      CreatedBy: new FormControl(''),
      CategoryId1:new FormControl(''),
      SCategoryId :new FormControl('')
    })

    this.productimgFormGroup = new FormGroup({
      Name: new FormControl(''),
      FileSize:new FormControl(''),
      Extension: new FormControl(''),
      Path:new FormControl(''),
      prductphotoId : new FormControl(''),
      uploadedImage: new FormControl(''),
    })

    // this.ProductFormGroup.get('CategoryId1').valueChanges.subscribe(response => {
    //   console.log('data is ', response);
    //   this.filterStandard(response);
    // })

  }

  // filterStandard(enterStandard){
  //   this.allStandard = this.Category.filter(CategoryId => {
  //     return CategoryId['CategoryName'].toLowerCase().indexOf(enterStandard.toLowerCase()) > -1
  //   })
  // }

  tempSubjectGrp(templateRef: any): void {
    let dialogRef = this.dialog.open(templateRef, { disableClose: true });
    }
 
  fngetProductID() {
    this.service.getProductLastId().subscribe((response) => {
      this.AllProducts = response
      if (this.AllProducts != 0) {
        this.ProductId = this.AllProducts[0].ProductId + 1
        // console.log(this.ProductId,'Products Id')
      }
      else {
        this.ProductId = 1;
      }
    })
  }

  fnaddProduct(){
    this.service.createProduct(this.ProductFormGroup.value).subscribe((response) => {
      // this.toastrService.success(':: Add Product Succesfully!');
    });

           
    
    
  
    const Path = "https://einvoiceapi.billsoft.co/profile/"+this.productimgFormGroup.value.Name 
      this.productimgFormGroup.controls.Path.setValue(Path);
      // console.log(Path);
    //  console.log("send data ",this.productimgFormGroup.value);
      this.service.createProfile(this.productimgFormGroup.value).subscribe((response) => {
     
      })

    const formData = new FormData();
    formData.append('uploadedImage', this.productimgFormGroup.get('uploadedImage').value);
    formData.append('agentId', '007');
  
  
    this.http
      .post<any>('https://einvoiceapi.billsoft.co/uploadfile', formData).subscribe(response => {
        // console.log(response);
        if (response.statusCode === 200) {
          // Reset the file input
          this.uploadFileInput.nativeElement.value = "";
          this.fileInputLabel = undefined;
          // console.log(formData)
          
        }
      }, er => {
        // console.log(er);
        alert(er.error.error);
      });


    this.getProducts()
  }
      
  fngetPicId() {
    this.service.getLastprductphotoId().subscribe((response) => {
      // console.log(this.FileId, 'file number');
      this.allpicId = response
      // this.lenOfQuotation=this.allgdown.length + 1;
      if (this.allpicId != 0) {
        this.prductphotoId  = this.allpicId[0].prductphotoId + 1
      // console.log(this.prductphotoId, 'file number');
      }
      else {
        this.prductphotoId = 1;
      }
    })
  }
    refresh() {
      
    }

 
      deleteProduct(id:any){
        console.log(id,)
    
 
        
              this.service.deleteProduct(id).subscribe((data)=>{
                this.service.warn('Deleted Succesfully')
                this.getProducts()
                 })
              
           
        }



      getStandardName(){
        this.service.getCategory(this.SystemId).subscribe((data) => {
          this.allStandard = data;
          this.Category = data;
          console.log("allStandard", this.allStandard);
        })
      }

      onFileSelect(event) {
        
        const file = event.target.files[0];
        this.fileInputLabel = file.Name;
        this.productimgFormGroup.get('uploadedImage').setValue(file);
        this.image  = file.Name;
        if (file) {
          const reader  = new FileReader();
          reader.onload = () => {
            this.image  = reader.result as string;
            console.log(file)
            const Name = this.productimgFormGroup.value.uploadedImage.name 
            this.productimgFormGroup.controls.Name.setValue(Name);
            console.log(Name)
            
            const FileSize = this.productimgFormGroup.value.uploadedImage.size
            this.productimgFormGroup.controls.FileSize.setValue(FileSize);
            
            const Extension = this.productimgFormGroup.value.uploadedImage.type 
            this.productimgFormGroup.controls.Extension.setValue(Extension);
          };
          reader.readAsDataURL(file);
          this.selectFile.emit(file);
        }
      }

     
      fngeCategory(e){
        this.service.getSCategory(e).subscribe((data) => {
          this.allsubCategory = data;
          this.Category = data;
          console.log("allsubCategory", this.allsubCategory);
        })
      }
      

      fngeCategory1(e){
        this.service.getBrandIdC(e).subscribe((data) => {
          this.BrandAll = data;
        })
      }
 
}
