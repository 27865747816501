import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { Product } from 'src/app/modals/product.model';
import {  SwiperDirective } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { ProductDialogComponent } from '../../products/product-dialog/product-dialog.component';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { WishlistService } from 'src/app/components/shared/services/wishlist.service';
import { AdminModuleServiceService } from 'src/app/admin-module/admin-module-service.service';

@Component({
  selector: 'app-product-carousel1',
  templateUrl: './product-carousel1.component.html',
  styleUrls: ['./product-carousel1.component.sass']
})
export class ProductCarousel1Component implements OnInit {
  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
  @Input('product') product: Array<Product> = [];
  public config: SwiperConfigInterface = {};
  UserId: any;
  UserTypeId: any;
  SystemId: any;
  allStandard: any=[{}];
  Category: any;
  constructor(private dialog: MatDialog, private router: Router, private cartService: CartService, private service: AdminModuleServiceService, private productService: ProductService, private wishlistService: WishlistService) { }

  ngOnInit() {
    window.scrollTo(0, 0)
    const data = JSON.parse(localStorage.getItem('access_token'))
    console.log(data);
    this.getStandardName();


    this.UserId  = data.user.UserId ;
    this.UserTypeId = data.user.UserType;
    this.SystemId = data.user.SystemId;
   console.log(this.SystemId);
    if (data == null) {
      this.router.navigate(['']);
    }

  }
  
  ngAfterViewInit(){
    this.config = {
      observer: true,
      slidesPerView: 4,
      spaceBetween: 16,
      keyboard: true,
      navigation: true,
      pagination: false,
      grabCursor: true,
      // loop: true,
      preloadImages: false,
      lazy: true,
      breakpoints: {
        480: {
          slidesPerView: 1
        },
        740: {
          slidesPerView: 2,
        },
        960: {
          slidesPerView: 3,
        },
        1280: {
          slidesPerView: 4,
        },
      }
    }
  }


  public openProductDialog(product){
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe(product => {
      if(product){
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }

   // Add to cart
   public addToCart(product: Product,  quantity: number = 1) {
    this.cartService.addToCart(product,quantity);
  }

   // Add to wishlist
   public addToWishlist(product: Product) {
    this.wishlistService.addToWishlist(product);
 }

    // Add to compare
    public addToCompare(product: Product) {
      this.productService.addToCompare(product);
   }

   getStandardName(){
    console.log("allStandard",this.SystemId);

    this.service.getProducts1(4).subscribe((data) => {
      this.allStandard = data;
      this.Category = data;
      console.log("allStandard", data);
    })


  }

  
}
