import { Component, EventEmitter, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { ActivatedRoute, Router } from '@angular/router';
import { ElementRef } from '@angular/core';


import {  FormControl, FormGroup, Validators } from '@angular/forms';
import { AdminModuleServiceService } from '../../admin-module-service.service';
import { HttpClient } from '@angular/common/http';



@Component({
  selector: 'app-update-product',
  templateUrl: './update-product.component.html',
  styleUrls: ['./update-product.component.sass']
})
export class UpdateProductComponent implements OnInit {
  @ViewChild('HomeworkDate') HomeworkDate: ElementRef;
  @ViewChild('SubmissionDate') SubmissionDate: ElementRef;
  @ViewChild('UploadFileInput', { static: false }) uploadFileInput: ElementRef;
  @Output() selectFile: EventEmitter<Object> = new EventEmitter();
  public image : string = 'https://www.w3schools.com/howto/img_avatar.png';
  fileInputLabel: string;
  UserTypeId: any;
  SystemId: any;

  EmployeeId: any;
  ProductFormGroup: FormGroup;
 
 
  AllProducts: Object;
  ProductId: any;
  singleProduct: any=[{}];
  productimgFormGroup: FormGroup;
  allStandard: Object;
  prductphotoId:any;
  Category: any;
  

  constructor(public dialog: MatDialog,
    private service: AdminModuleServiceService,
    private route: Router, private router: ActivatedRoute,@Inject(MAT_DIALOG_DATA) public data: any,private http: HttpClient ) { }


  ngOnInit(): void {
    const data = JSON.parse(localStorage.getItem('access_token'))
    console.log(data);

    const data1 = JSON.parse(localStorage.getItem('AcademicYearId'))
    console.log(data1);
    
    this.EmployeeId = data.user.EmployeeId;
    this.UserTypeId = data.user.UserType;
    this.SystemId = data.user.SystemId;
   
    if (data == null) {
      this.route.navigate(['']);
    }

    this.getStandardName();

    

    // this.fngetProductID();
   
    this.ProductFormGroup = new  FormGroup({
      ProductId : new FormControl(''),
      ProductName: new FormControl(''),
      Description :new FormControl(''),  
      CategoryId :new FormControl(''),
      // CategoryId1:new FormControl(''),
      prductphotoId:new FormControl(''),
      Image: new FormControl(''),
      SystemId: new FormControl(''),
      // CreatedBy: new FormControl(''),
    })

    this.productimgFormGroup = new FormGroup({
      Name: new FormControl(''),
      FileSize:new FormControl(''),
      Extension: new FormControl(''),
      Path:new FormControl(''),
      prductphotoId: new FormControl(''),
      uploadedImage: new FormControl(''),
    })

    this.service.getSingleProduct(this.data.ProductId).subscribe(
      (response) => {
        this.singleProduct = response[0];
        this.image = response[0].Path
     console.log(this.singleProduct);
     
      })

  }


 
  // fngetProductID() {
  //   this.service.getProductLastId().subscribe((response) => {
  //     this.AllProducts = response
  //     if (this.AllProducts != 0) {
  //       this.ProductId = this.AllProducts[0].ProductId + 1
  //       console.log(this.ProductId,'Products Id')
  //     }
  //     else {
  //       this.ProductId = 1;
  //     }
  //   })
  // }

  
  fnUpdateProduct(id:any){
    this.service.updateProduct(id,this.ProductFormGroup.value).subscribe((response) => {
     console.log(id,"sdfdsfdf");
     this.refresh();
    });

  }
      
    refresh() {
      window.location.reload()
    }

    getStandardName(){
      this.service.getCategory(this.SystemId).subscribe((data) => {
        this.allStandard = data;
        this.Category = data;
        console.log("allStandard", this.allStandard);
      })
    }

    UpdateProfile(id:any) {

      const Path = "http://localhost:5000/uploadfile/"+this.productimgFormGroup.value.uploadedImage.name 
      this.productimgFormGroup.controls.Path.setValue(Path);
       
        // this.service.createProfile(this.profileFormGroup.value).subscribe((response) => {
       
        // })
  
       if (!this.productimgFormGroup.get('uploadedImage').value) {
        return false;
      }
    
      const formData = new FormData();
      formData.append('uploadedImage', this.productimgFormGroup.get('uploadedImage').value);
      formData.append('agentId', '007');
    
    
      this.http
        .post<any>('http://localhost:5000/uploadfile', formData).subscribe(response => {
    
          if (response.statusCode === 200) {
            // Reset the file input
            this.uploadFileInput.nativeElement.value = "";
            this.fileInputLabel = undefined;
          }
        }, er => {
         
          alert(er.error.error);
        });
  
       
        
            console.log(this.singleProduct.prductphotoId , this.productimgFormGroup.value)
            this.service.updateproductProfile(this.singleProduct.prductphotoId , this.ProductFormGroup.value).subscribe(
              (response) => {
                // this.router.navigate([`/admin/member/members/`]);
                // this.toastrService.success('Student Profile Picture Updated Succesfully')
    
              })
         
    }
    
    onFileSelect(event) {
      const file = event.target.files[0];
      this.fileInputLabel = file.Name;
      this.productimgFormGroup.get('uploadedImage').setValue(file);
      this.image  = file.Name;
      if (file) {
        const reader  = new FileReader();
        reader.onload = () => {
          this.image  = reader.result as string;
          console.log(file)
          const Name = this.productimgFormGroup.value.uploadedImage.name 
          this.productimgFormGroup.controls.Name.setValue(Name);
          console.log(Name)
          
          const FileSize = this.productimgFormGroup.value.uploadedImage.size
          this.productimgFormGroup.controls.FileSize.setValue(FileSize);
          
          const Extension = this.productimgFormGroup.value.uploadedImage.type 
          this.productimgFormGroup.controls.Extension.setValue(Extension);
        };
        reader.readAsDataURL(file);
        this.selectFile.emit(file);
      }
    }
  
}
