import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { AdminModuleServiceService } from '../admin-module-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UpdateBrandComponent } from './update-brand/update-brand.component';

export interface assignSubDataInterface {
  ItemCategoriesId: number,
  ItemCategory: string;
};


@Component({
  selector: 'app-admin-brand',
  templateUrl: './admin-brand.component.html',
  styleUrls: ['./admin-brand.component.sass']
})
export class AdminBrandComponent implements OnInit {
  UserTypeId: any;
  SystemId: any;

  EmployeeId: any;
  CategoryFormGroup: FormGroup;
  allsubCategory: Object;
  allCategory:Object;
  AllProducts: Object;
  BrandId: any;
  UserId: any;
  Category: any;
  constructor(
    private service: AdminModuleServiceService,
    private route: Router, private router: ActivatedRoute, public dialog: MatDialog) { }


  allassignSub!: assignSubDataInterface[];
  assignSubDisplayColumn = ['Sr.No','Brand Name','Description','Action'];
  assignSubDataSource = new MatTableDataSource(this.allassignSub)

  searchClient(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.assignSubDataSource.filter = filterValue.trim().toLowerCase();
  }

  async getBrands() {
    const teacher = await this.service.getAllBrand().toPromise();
    console.log(teacher,"teacher");
    this.assignSubDataSource.data = teacher as assignSubDataInterface[]
  }

  fnOpenUpdateBrand(BrandId:any) {
   let dialogref = this.dialog.open(UpdateBrandComponent,{
      data:{
        BrandId,
        Text:true
      },
      disableClose:true});

    // this.dialog._getAfterAllClosed().subscribe(result => {
    //       this.getProducts();
    // });

  }

  ngOnInit(): void {
    const data = JSON.parse(localStorage.getItem('access_token'))
    console.log(data);

    this.UserId = data.user.UserId;
    this.UserTypeId = data.user.UserType;
    this.SystemId = data.user.SystemId;
   
    if (data == null) {
      this.route.navigate(['']);
    }

    // this.getHomework();
    this.fngetBrandID();
   this.getBrands();
   this.getsubcategoryName();
   this.getCatagoryName();
    this.CategoryFormGroup = new  FormGroup({
      BrandId  : new FormControl(''),
      BrandName: new FormControl(''),
      CategoryId: new FormControl(''),
      BrandDescription :new FormControl(''),  
      SCategoryId : new FormControl(''),
      // SystemId: new FormControl(''),
      // CreatedBy: new FormControl(''),
    })

  }

  tempSubjectGrp(templateRef: any): void {
    let dialogRef = this.dialog.open(templateRef, { disableClose: true });
    }
 
  fngetBrandID() {
    this.service.getBrandId().subscribe((response) => {
      this.AllProducts = response
      if (this.AllProducts != 0) {
        this.BrandId = this.AllProducts[0].BrandId + 1
        console.log(this.BrandId,'Products Id')
      }
      else {
        this.BrandId = 1;
      }
    })
  }

  fnaddBrand(){
    this.service.createBrand(this.CategoryFormGroup.value).subscribe((response) => {
      // this.toastrService.success(':: Add Product Succesfully!');
      this.getBrands();
      this.clrfun();
    });

    
   
  }
     clrfun(){
      this.CategoryFormGroup.controls.SCategoryName.reset();
      this.CategoryFormGroup.controls.SCategoryDescription.reset();
      this.CategoryFormGroup.controls.CategoryId.reset();

     } 
    refresh() {
      
    }

    deleteBrand(id:any){
            this.service.deleteBrand(id).subscribe((data)=>{
              this.service.warn('Deleted Succesfully')
              this.getBrands();
            // this.t
           
               })
            
         
      } 

      getCatagoryName(){
        this.service.getCategory(this.SystemId).subscribe((data) => {
          this.allCategory = data;
          this.Category = data;
          console.log("allStandard", this.allCategory);
        })
      }

      getsubcategoryName(){

      }
     
      fngeCategory(e){
        this.service.getSCategory(e).subscribe((data) => {
          this.allsubCategory = data;
          this.Category = data;
          console.log("allsubCategory", this.allsubCategory);
        })
      }
}
