import {RouterModule, Routes} from '@angular/router';
import { AuthGuard } from '../guards/auth.guard';




export const admin: Routes = [
 

 
];

