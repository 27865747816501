import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { CartService } from 'src/app/components/shared/services/cart.service';
import { ProductService } from 'src/app/components/shared/services/product.service';
import { WishlistService } from 'src/app/components/shared/services/wishlist.service';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Product } from 'src/app/modals/product.model';
import { ProductDialogComponent } from '../product-dialog/product-dialog.component';
import { AdminModuleServiceService } from 'src/app/admin-module/admin-module-service.service';

@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit {

  @Output() onOpenProductDialog: EventEmitter<any> = new EventEmitter();
 @Input() product: number = 1  ;


 UserId: any;
 UserTypeId: any;
 SystemId: any;
 allStandard: any=[{}];
 Category: any;
  CategoryProd: any;
  Sysobj: any;
  sharedData: string;

  abc: number;
 constructor(private dialog: MatDialog, private router: Router, private cartService: CartService, private service: AdminModuleServiceService, private productService: ProductService, private wishlistService: WishlistService) { }

  ngOnInit() {
    window.scrollTo(0, 0)

   

if(this.product==1){

  this.service.getAllProducts(this.product).subscribe(data => {
    this.CategoryProd = data;
    console.log(data)
  })
}else{
  this.service.getProductByCategoryId(this.product).subscribe(data => {
    this.CategoryProd = data;
    console.log(data)      
    this.Category = data;

  })
}


   
  this.getdatasort();
  

   this.getStandardName();
  }

     // Add to cart
     public addToCart(product: Product,  quantity: number = 0) {
      this.cartService.addToCart(product,quantity);
      
    }

    // Add to wishlist
    public addToWishlist(product: Product) {
      this.wishlistService.addToWishlist(product);
   }

    // Add to compare



  public openProductDialog(product){
    let dialogRef = this.dialog.open(ProductDialogComponent, {
        data: product,
        panelClass: 'product-dialog',
    });
    dialogRef.afterClosed().subscribe(product => {
      if(product){
        this.router.navigate(['/products', product.id, product.name]);
      }
    });
  }


  getStandardName(){
    console.log(this.CategoryProd)
    this.service.getProducts(1).subscribe((data) => {
      this.allStandard = data[0];
    
   

    })
  }

getdatasort(){
  this.service.sharedData$
  .subscribe(sharedData => this.sharedData = sharedData);



  this.CategoryProd = this.Category.filter(PartiesCategoryId1 => {
console.log(PartiesCategoryId1)
return PartiesCategoryId1['ProductName'].toLowerCase().indexOf(this.sharedData.toLowerCase()) > -1
  })

  
}



}
