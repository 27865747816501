import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-admin-sidebar',
  templateUrl: './admin-sidebar.component.html',
  styleUrls: ['./admin-sidebar.component.sass']
})
export class AdminSidebarComponent implements OnInit {

  constructor() { }
  winUrl: 'www.google.com'
  ngOnInit(): void {
  }



  openWindow() {
    window.open(this.winUrl);
  }
}
