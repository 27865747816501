import { Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialog } from '@angular/material/dialog';
import { AdminModuleServiceService } from '../../admin-module-service.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-update-catagories',
  templateUrl: './update-catagories.component.html',
  styleUrls: ['./update-catagories.component.sass']
})
export class UpdateCatagoriesComponent implements OnInit {


  UserTypeId: any;
  SystemId: any;

  EmployeeId: any;
  ProductFormGroup: FormGroup;
 
 
  AllProducts: Object;
  ProductId: any;
  singlecatagory: any;

  allStandard: Object;
  Category: any;
  

  constructor(public dialog: MatDialog,
    private service: AdminModuleServiceService,
    private route: Router, private router: ActivatedRoute,@Inject(MAT_DIALOG_DATA) public data: any, ) { }


  ngOnInit(): void {
    const data = JSON.parse(localStorage.getItem('access_token'))
    console.log(data);

    const data1 = JSON.parse(localStorage.getItem('AcademicYearId'))
    console.log(data1);
    
    this.EmployeeId = data.user.EmployeeId;
    this.UserTypeId = data.user.UserType;
    this.SystemId = data.user.SystemId;
   
    if (data == null) {
      this.route.navigate(['']);
    }

    this.getCatagoryName();

    this.service.getSingleCategory(this.data.CategoryId,this.SystemId).subscribe(
      (response) => {
        this.singlecatagory = response[0];
     console.log(this.singlecatagory);
     
      })


    // this.fngetProductID();
   
    this.ProductFormGroup = new  FormGroup({
      CategoryId : new FormControl(''),
      CategoryName: new FormControl(''),
      CategoryDescription :new FormControl(''),  
     
      // CategoryId1:new FormControl(''),
      // Image: new FormControl(''),
      SystemId: new FormControl(''),
      // CreatedBy: new FormControl(''),
    })
  }


 
  // fngetProductID() {
  //   this.service.getProductLastId().subscribe((response) => {
  //     this.AllProducts = response
  //     if (this.AllProducts != 0) {
  //       this.ProductId = this.AllProducts[0].ProductId + 1
  //       console.log(this.ProductId,'Products Id')
  //     }
  //     else {
  //       this.ProductId = 1;
  //     }
  //   })
  // }

  fnUpdateCategory(id:any){
    this.service.updateCategory(id,this.ProductFormGroup.value).subscribe((response) => {
     console.log(id,"sdfdsfdf");
     this.refresh();
    });

  }
      
    refresh() {
      window.location.reload()
    }

    getCatagoryName(){
      this.service.getCategory(this.SystemId).subscribe((data) => {
        this.allStandard = data;
        this.Category = data;
        console.log("allStandard", this.allStandard);
      })
    }
  
}

