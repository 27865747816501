import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private http: HttpClient, private router: Router,private dialog: MatDialog) { }

  logout() {
    localStorage.clear();
    this.router.navigate([''])
  }
  IsLoggedIn() {
    return !!localStorage.getItem('jwt_token')
  }
}
