import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AdminLoginComponent } from './admin-login/admin-login.component';
import { AdminProductComponent } from './admin-product/admin-product.component';
import { AdminMainComponent } from './admin-main/admin-main.component';
import { UpdateProductComponent } from './admin-product/update-product/update-product.component';
import { AdminCategorieComponent } from './admin-categorie/admin-categorie.component';
import { AdminBrowsproductComponent } from './admin-browsproduct/admin-browsproduct.component';
import { AdminEditnewsComponent } from './admin-editnews/admin-editnews.component';
import { UpdateCatagoriesComponent } from './admin-categorie/update-catagories/update-catagories.component';
import { AdminSubcategoryComponent } from './admin-subcategory/admin-subcategory.component';
import { UpdateSubcategoryComponent } from './admin-subcategory/update-subcategory/update-subcategory.component';
import { AdminBrandComponent } from './admin-brand/admin-brand.component';
import { UpdateBrandComponent } from './admin-brand/update-brand/update-brand.component';



const routes: Routes = [
  { path: 'Adminlog', component: AdminLoginComponent },
  {path:'product-ad', component:AdminProductComponent},
  {path:'admin-main', component:AdminMainComponent},
  {path:'admin-product', component:AdminProductComponent},
  {path:'product-ad/update-product/:id', component:UpdateProductComponent},
  {path:'catagory/update-catagory/:id', component:UpdateCatagoriesComponent},
  {path:'subcomp/update-scatagory/:id', component:UpdateSubcategoryComponent},
  {path:'catagory',component:AdminCategorieComponent},
  {path:'adminlogmain', component:AdminMainComponent},
  {path:'admin/brows',component:AdminBrowsproductComponent},
  {path:'admin/editnews',component:AdminEditnewsComponent},
  {path:'subcomp',component:AdminSubcategoryComponent},
  {path: 'admin',component:AdminLoginComponent},
  {path:'brand',component:AdminBrandComponent},
  {path:'brand/update-brands/:id', component:UpdateBrandComponent},
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class AdminModuleRoutingModule { }
