import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxImgZoomModule } from 'ngx-img-zoom';

import { MainComponent } from './components/main/main.component';

import { HashLocationStrategy, LocationStrategy } from '@angular/common';


import { AppRoutingModule } from './app-routing.module';
import { ShopModule } from './components/shop/shop.module';
import { SharedModule } from './components/shared/shared.module';
import {MatExpansionModule} from '@angular/material/expansion';
import { AdminModuleModule } from './admin-module/admin-module.module';
import { MainLauoutComponent } from './layout/main-lauout/main-lauout.component';
import { AdminBrowsproductComponent } from './admin-module/admin-browsproduct/admin-browsproduct.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { AdminFooterComponent } from './shared-admin/admin-footer/admin-footer.component';
import { AdminSidebarComponent } from './shared-admin/admin-sidebar/admin-sidebar.component';
import { AdminHeaderComponent } from './shared-admin/admin-header/admin-header.component';




@NgModule({
  declarations: [
    AppComponent,
   
    MainLauoutComponent,
    
    AdminBrowsproductComponent,
    
    AdminLayoutComponent,
    
    AdminSidebarComponent,
    
    AdminFooterComponent,
    
    AdminHeaderComponent,
    

  ],
  imports: [
    NgxSpinnerModule,
    BrowserModule,
    SharedModule,
    ShopModule,
    HttpClientModule,
    BrowserAnimationsModule,
    FormsModule,
    
    ReactiveFormsModule,
    MatExpansionModule,
    AppRoutingModule,
    AdminModuleModule
   
    

  ],
  exports: [
   
  ],  

  providers: [{provide: LocationStrategy, useClass: HashLocationStrategy}],
  bootstrap: [AppComponent]
})
export class AppModule { }
