import { NgModule } from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import { MainComponent } from './components/main/main.component';
import { HomeComponent } from './components/shop/home/home.component';
import { AdminLoginComponent } from './admin-module/admin-login/admin-login.component';
import { MainLauoutComponent } from './layout/main-lauout/main-lauout.component';
import { appRoutes } from './routes/Admin-layout.route';
import { AdminMainComponent } from './admin-module/admin-main/admin-main.component';
import { AdminLayoutComponent } from './layout/admin-layout/admin-layout.component';
import { admin } from './routes/Admin1-layout.route';

 const routes: Routes = [
  
  {path: '', component:MainLauoutComponent,children: appRoutes},
  {path:'admin',component:AdminLayoutComponent,children: admin},
  
  

 
];

@NgModule({
  declarations: [],
  imports: [
    RouterModule.forRoot(routes, {
      scrollPositionRestoration: 'disabled'
    })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
