import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-update-browse',
  templateUrl: './update-browse.component.html',
  styleUrls: ['./update-browse.component.sass']
})
export class UpdateBrowseComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
