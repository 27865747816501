import { Component, OnInit } from '@angular/core';
import { ProductService } from '../../shared/services/product.service';
import { ColorFilter, Product } from 'src/app/modals/product.model';
import { AdminModuleServiceService } from 'src/app/admin-module/admin-module-service.service';
import { FormControl, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {
  products: Product[];
  public banners = [];
  public slides = [
    { title: 'THE BEST CHOICE IS HERE', subtitle: 'New Arrivals On Sale', image: 'assets/images/carousel/banner1.jpg' },
    { title: 'Biggest discount', subtitle: 'Check the promotion', image: 'assets/images/carousel/banner1.jpg' },
    { title: 'Biggest sale', subtitle: 'Dont miss it', image: 'assets/images/carousel/banner1.jpg' },

  ];
  
  public sidenavOpen:boolean = true;
  public animation    :   any;   // Animation
  public sortByOrder  :   string = '';   // sorting
  public page:any;
  public tagsFilters  :   any[] = [];
  public viewType: string = 'grid';
  public viewCol: number = 25;
  public colorFilters :   ColorFilter[] = [];

  public items        :   Product[] = [];
  public allItems: Product[] = [];
  public tags         :   any[] = [];
  public colors       :   any[] = [];
  UserTypeId: any;
  SystemId: any;

  EmployeeId: any;
  getdatabyid :number = 1;
  allStandard: any;
  Sysobj: any;
 
  AllProducts: Object;
  ProductId: any;
  UserId: any;
  Category: any;

  selectedItem  : number;
  ProductFormGroup: FormGroup;
  CategoryProd: any;
  item: number;
  allsubCategory: Object;
  sharedData: string;

  constructor(private productService: ProductService,private service: AdminModuleServiceService,) { }

  ngOnInit() {

    this.service.sharedData$
    .subscribe(sharedData => this.sharedData = sharedData);
    const data = JSON.parse(localStorage.getItem('access_token'))
    console.log(data);
    this.getStandardName();

    if (data == null) {
    }

    // this.getHomework();
  

   this.ProductFormGroup = new  FormGroup({
    ProductId : new FormControl(''),
    ProductName: new FormControl(''),
    Description :new FormControl(''),  
    SCategoryId:new FormControl(''),
    CategoryId: new FormControl(''),
    Image: new FormControl(''),
    SystemId: new FormControl(''),
    CreatedBy: new FormControl(''),
    CategoryId1:new FormControl('')
  })
    this.productService.getBanners()
    .subscribe(
      data => this.banners = data
    );

 this.productService.getProducts()
 .subscribe(
   (product: Product[]) => {
     this.products = product
   }
 )

  }

     // Get current product tags
     public getTags(products) {
      var uniqueBrands = []
      var itemBrand = Array();
      products.map((product, index) => {
         if(product.tags) {
            product.tags.map((tag) => {
            const index = uniqueBrands.indexOf(tag);
            if(index === -1)  uniqueBrands.push(tag);
         })
        }
      });
      for (var i = 0; i < uniqueBrands.length; i++) {
           itemBrand.push({brand:uniqueBrands[i]})
      }
      this.tags = itemBrand
   }

     // Get current product colors
     public getColors(products) {
      var uniqueColors = []
      var itemColor = Array();
      products.map((product, index) => {
        if(product.colors) {
        product.colors.map((color) => {
            const index = uniqueColors.indexOf(color);
            if(index === -1)  uniqueColors.push(color);
        })
       }
      });
      for (var i = 0; i < uniqueColors.length; i++) {
           itemColor.push({color:uniqueColors[i]})
      }
      this.colors = itemColor
   }



  public changeViewType(viewType, viewCol){
    this.viewType = viewType;
    this.viewCol = viewCol;
  }
    // Animation Effect fadeIn
    public fadeIn() {
      this.animation = 'fadeIn';
  }

  // Animation Effect fadeOut
  public fadeOut() {
      this.animation = 'fadeOut';
  }

    // Update tags filter
    public updateTagFilters(tags: any[]) {
      this.tagsFilters = tags;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
  }



    // sorting type ASC / DESC / A-Z / Z-A etc.
    public onChangeSorting(val) {
      this.sortByOrder = val;
      this.animation == 'fadeOut' ? this.fadeIn() : this.fadeOut(); // animation
   }

     // Initialize filetr Items
  public filterItems(): Product[] {
    return this.items.filter((item: Product) => {
        const Colors: boolean = this.colorFilters.reduce((prev, curr) => { // Match Color
          if(item.colors){
            if (item.colors.includes(curr.color)) {
              return prev && true;
            }
          }
        }, true);
        const Tags: boolean = this.tagsFilters.reduce((prev, curr) => { // Match Tags
          if(item.tags) {
            if (item.tags.includes(curr)) {
              return prev && true;
            }
          }
        }, true);
        return Colors && Tags; // return true
    });

}

public onPageChanged(event){
  this.page = event;
  this.allItems;
  window.scrollTo(0,0);
  console.log(event);
}


  // Update price filter
//   public updatePriceFilters(price: any) {
//     let items: any[] = [];
//     this.products.filter((item: Product) => {
//         if (item.price >= price[0] && item.price <= price[1] )  {
//            items.push(item); // push in array
//         }
//     });
//     this.items = items;
// }


  // Update price filter
  public updatePriceFilters(price: any) {
    console.log(price);
    console.log(this.products);


   this.allItems = this.products.filter((item: Product) => {
     return item.price >= price.priceFrom && item.price <= price.priceTo
    });
     console.log(this.products);

}

onBrendsChanged(newBrend) {
  console.log(newBrend);
  this.allItems = newBrend === 'all' ? this.products : this.products.filter(

    item => item.brand === newBrend
  )
  console.log(this.allItems);



}

getallproduct(){
  this.service.getProducts(1).subscribe((data) => {
    this.Sysobj = data[0];
    this.Category = data;
    

   
  })
}


getStandardName(){
  this.service.getCategory(1).subscribe((data) => {
    this.allStandard = data;
    this.Category = data;
    console.log("allStandard", this.allStandard);
  })
}

fngetReport(e){

this.selectedItem = e;
  console.log(e,"selectedItem")
{
   
    this.service.getProductByCategoryId(e).subscribe(data => {
      this.CategoryProd = data;

      console.log(data,"data")

  })
}





}

getScatagory(e){

  this.selectedItem = e;
    console.log(e,"selectedItem")
  {
     
      this.service.getscatagoryByCategoryId(e).subscribe(data => {
        this.CategoryProd = data;
  
        console.log(data,"data")
  
    })
  }
  
  
  
  
  
  }

Getcatagoryid(e){

}

getsubcategoryName(){
  this.service.getSCategory(1).subscribe((data) => {
    this.allsubCategory = data;
    this.Category = data;
    console.log("allsubCategory", this.allsubCategory);
  })
}


}
