import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-admin-header',
  templateUrl: './admin-header.component.html',
  styleUrls: ['./admin-header.component.sass']
})
export class AdminHeaderComponent implements OnInit {
  @Output() toggleSideBarForMe: EventEmitter<any> = new EventEmitter();

  constructor(private authService:AuthService) { }
  toggleSideBar(){
    this.toggleSideBarForMe.emit()
  }
  logout(){
    this.authService.logout()
  }
  ngOnInit(): void {
  }

}
